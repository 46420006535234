import React from "react"
import styled from "styled-components"

const Grid = ({ children }) => {
  const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr minmax(343px, 10fr) 1fr;

    @media screen and (min-width: 800px) {
      grid-template-columns: 1fr minmax(550px, 22fr) 1fr;
    }

    > * {
      grid-column: 2 / 3;
    }
  `

  return <GridContainer>{children}</GridContainer>
}

export default Grid
